<template>
  <div class="practice-box">
    <el-dialog
      :title="this.$t('message.notice')"
      :visible.sync="showLeaveAlert"
      width="30%"
      :close-on-click-modal="false"
      center
    >
      <div>
        <div
          v-html="
            $t('Tests.leaveAlert', {
              counts: this.leaveCountText,
              times: this.leaveSeconds,
              second: this.second
            })
          "
        ></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="showLeaveAlert = false">
          {{ this.$t("message.continue") }}
        </el-button>
      </span>
    </el-dialog>
    <template v-if="question && testTime">
      <template v-if="page === 1">
        <TestingTools
          @pageEnd="nextPage"
          @returnToHome="returnToHome"
          :showCalculator="showCalculator"
          :showReference="showReference"
          :practiceType="practiceType"
        />
      </template>
      <template v-if="page === 2">
        <template v-if="showQuestion">
          <QuestionWrapper
            ref="QuestionWrapper"
            :questions="questions"
            :sectionOrder="sectionOrder"
            :sectionName="sectionName"
            :examineeName="examineeName"
            :questionOrder="parseInt(questionOrder)"
            :questionCount="questions.length"
            :examTimeSeconds="testTime"
            :isReviewPage="isReviewPage"
            :hasTime="hasTime"
            @onBackQuestion="onBackQuestion"
            @onNextQuestion="onNextQuestion"
            @onOrderQuestion="onOrderQuestion"
            @onSubmitSection="onSubmitSection"
            @onTestTimeout="onTestTimeout"
            @onExitTest="onExitTest"
            @onToReviewPage="onToReviewPage"
            @onReviewPage="onReviewPage"
            @setPassage="setPassage"
            :showAnnotate="
              sectionName !== 'Math' && sectionName !== 'Math calculator'
            "
            :showCalculator="
              sectionName === 'Math calculator' ||
                (isNew && sectionName === 'Math')
            "
            :showReference="
              sectionName === 'Math' || sectionName === 'Math calculator'
            "
            :formAnnotation="
              question &&
              question.metadata &&
              question.metadata.annotation &&
              question.metadata.annotation.annotation
                ? question.metadata.annotation.annotation
                : null
            "
          >
            <div class="test-paper">
              <template v-if="isReviewPage">
                <Review
                  :questions="questions"
                  :sectionOrder="sectionOrder"
                  :sectionName="sectionName"
                  @onOrderQuestionFromReview="onOrderQuestionFromReview"
                ></Review>
                <!-- <SATReviewPage></SATReviewPage> -->
              </template>
              <template v-else>
                <MultipleChoiceWithPassage
                  v-if="
                    question.question &&
                      question.question.type === 'single' &&
                      question.question.passage_id > 0 &&
                      question.question.passage.content
                  "
                  :key="question.order"
                  :passageIntro="
                    question.question.passage.introduction && !this.isNew
                      ? question.question.passage.introduction.intro
                      : null
                  "
                  :passageContent="getPassage"
                  :questionOrder="parseInt(questionOrder)"
                  :content="question.question.content"
                  :options="question.question.options"
                  :answer="answer ? answer : ''"
                  :marked="marked"
                  :crossOutActive="crossOutActive"
                  :crossOutArr="crossOutArr"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                  @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
                  @onChangeCrossOut="onChangeCrossOut"
                  :showLine="sectionName === 'Reading'"
                ></MultipleChoiceWithPassage>
                <MultipleChoice
                  v-else-if="
                    question.question &&
                      question.question.type === 'single' &&
                      (question.question.passage === null ||
                        question.question.passage.content === null)
                  "
                  :questionOrder="parseInt(questionOrder)"
                  :content="question.question.content"
                  :options="question.question.options"
                  :answer="answer ? answer : ''"
                  :marked="marked"
                  :crossOutActive="crossOutActive"
                  :crossOutArr="crossOutArr"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                  @onSwitchCrossOutStatus="onSwitchCrossOutStatus"
                  @onChangeCrossOut="onChangeCrossOut"
                ></MultipleChoice>
                <Math
                  v-else-if="question.question.type === 'math'"
                  :questionOrder="parseInt(questionOrder)"
                  :content="question.question.content"
                  :options="question.question.options"
                  :answer="answer ? answer : ''"
                  :marked="marked"
                  @onSwitchMarkStatus="onSwitchMarkStatus"
                  @onChangeAnswer="onChangeAnswer"
                ></Math>
              </template>
            </div>
          </QuestionWrapper>
        </template>
        <template v-else>
          <BreakScreen
            :examineeName="examineeName"
            :breakTimeSeconds="
              breakTime[`section${sectionOrder - 1}${sectionOrder}`].time
            "
            @onBreakTimeout="showQuestion = true"
          />
        </template>
      </template>
      <el-dialog
        :title="this.$t('message.notice')"
        :visible.sync="showLeaveAlert"
        width="30%"
        :close-on-click-modal="false"
        center
      >
        <div>
          <h6
            v-html="
              $t('toefl.leaveAlert', {
                counts: this.leaveCountText,
                times: this.leaveSeconds,
                second: this.second
              })
            "
          ></h6>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button
            type="default"
            size="small"
            @click="showLeaveAlert = false"
          >
            {{ this.$t("message.continue") }}
          </el-button>
        </span>
      </el-dialog>
    </template>
  </div>
</template>

<script>
import $ from "jquery";
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import common from "@/mixins/common";
import { user } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import { instant } from "@ivy-way/material";

// import QuestionWrapper from "@/views/sat/satTest/components/QuestionWrapper";
// import MultipleChoiceWithPassage from "@/views/sat/satTest/components/MultipleChoiceWithPassage";
import SATTest from "@/views/sat/apis/Test.js";

export default {
  metaInfo() {
    return {
      title: "Test - " + this.CompanyName
    };
  },

  components: {},

  mixins: [common],

  props: [],
  data() {
    return {
      loading: false,
      canHighlighter: false,
      canEliminator: false,
      canMasking: false,
      // old
      timeType: 1,
      isReviewPage: false,
      userExamAnswers: null,
      testTime: 0,
      adaptivePractice: null,
      sectionOrder: 1,
      page: 1,
      questionOrder: 1,
      practice: null,
      questions: null,
      answer: "",
      marked: false,
      crossOutActive: false,
      crossOutArr: [],
      maskArr: [],
      userExamId: 0,
      practices: [],
      showQuestion: true,
      examId: 0,
      showLeaveAlert: false,
      spent: 0,
      leaveSeconds: 0,
      leaveTimes: 0,
      questionTimeLine: 0,
      isNew: true,
      other_status: null,
      activities: [],
      annotation: {
        passage: null,
        annotation: []
      },
      subjects: [],
      question: null,
      adaptiveLine: {
        first_correct_count: 0,
        last_correct_count: 0,
        line: {
          first: null
        }
      }
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    ...mapGetters("user", ["token", "getProfile"]),
    sectionName() {
      return this.titleCase(this.practice.name);
    },
    breakTime() {
      if (this.isNew) {
        return {
          section12: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section34: {
            hasBreak: false,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      } else {
        return {
          section12: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          },
          section23: {
            hasBreak: true,
            time: this.timeType ? 5 * this.timeType : 5
          },
          section34: {
            hasBreak: true,
            time: this.timeType ? 600 * this.timeType : 600
          }
        };
      }
    },
    instant() {
      return instant;
    },
    showCalculator() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    showReference() {
      let show = false;
      if (this.practiceType === "singlePractice") {
        if (this.practice.subject_id === 3 || this.practice.subject_id === 4) {
          show = true;
        }
      } else {
        show = true;
      }
      return show;
    },
    pastUserExamId() {
      return this.$route.query.user_exam_id;
    },
    examineeName() {
      const { first_name, last_name } = this.$store.getters["user/getProfile"];
      return user.displayName(first_name, last_name);
    },
    getPassage() {
      return this.question.question.passage.content;
    },
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    testId() {
      return this.$route.params.id;
    },
    practiceType() {
      return this.$route.params.practiceType;
    },
    hasTime() {
      return (
        !(
          this.practice &&
          this.practice.marks &&
          this.practice.marks[1] &&
          this.practice.marks[1].name.en === "drill"
        ) && this.timeType > 0
      );
    }
  },
  watch: {},

  async mounted() {
    await this.startTest();
    this.testTime = this.setExamTimeSeconds();
    this.leaveAlert();
  },
  destroyed() {
    window.removeEventListener("beforeunload", e =>
      this.beforeUnloadHandler(e)
    );
    window.removeEventListener("unload", e => this.unloadHandler(e));
  },

  methods: {
    //SAT
    onChangeCrossOut(status) {
      if (status.type === "crossOut") {
        if (this.crossOutArr.indexOf(status.letter) === -1) {
          this.crossOutArr.push(status.letter);
        }
        if (this.question.answer === status.letter) {
          this.answer = null;
          this.question.question.answer = null;
          this.question.answer = null;
        }
        this.question.metadata.activities[
          this.question.metadata.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: `Crossed out ${status.letter}`,
          type: null
        });
      } else if (status.type === "undo") {
        let index = this.crossOutArr.indexOf(status.letter);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
        this.question.metadata.activities[
          this.question.metadata.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: `Un-Crossed out ${status.letter}`,
          type: null
        });
      }
      this.question["crossOutArr"] = this.crossOutArr;
    },
    onSwitchCrossOutStatus(status) {
      this.crossOutActive = status.crossOutActive;
      this.question["crossOutActive"] = this.crossOutActive;
    },
    onReviewPage() {
      this.isReviewPage = false;
    },
    onSwitchMarkStatus(status) {
      this.marked = status.marked;
      this.question["isMarked"] = this.marked;
    },
    setPassage(passage) {
      this.question.question.passage.content = passage.passageHtml
        ? passage.passageHtml
        : this.question.question.passage.content;
      this.question.question.content = passage.questionHtml
        ? passage.questionHtml
        : this.question.question.content;
      if (this.question.question.options) {
        this.question.question.options.forEach((option, index) => {
          if (passage.optionsHtml) {
            option.title = passage.optionsHtml[index].innerHTML;
          }
        });
      }
      let annotation = {
        annotation: passage.annotation,
        passage: this.question.question.passage.content,
        question: this.question.question.content,
        options: this.question.question.options
      };
      this.question.metadata.annotation = annotation;
      this.$refs.QuestionWrapper.setHighlight();
      let content = passage.passage.slice(0, 10);
      this.question.metadata.activities[
        this.question.metadata.activities.length - 1
      ].push({
        testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
        content: `Annotated "${content}..."`,
        type: null
      });
    },
    onToReviewPage(order) {
      this.updateAnswers();
      this.questionOrder = order;
      this.isReviewPage = true;
    },
    onOrderQuestionFromReview(order) {
      this.questionOrder = order;
      this.setQuestion();
      this.isReviewPage = false;
    },
    onTestTimeout(seconds) {
      this.timeOut(seconds);
    },
    async timeOut(seconds) {
      if (this.sectionOrder !== this.practices.length) {
        await this.updateSatAnswers(
          false,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        //适应性考试的切换单元
        if (this.practiceType === "adaptivePractices") {
          let correct_count = 0;
          this.questions.forEach(item => {
            if (item.is_correct) {
              correct_count++;
            }
          });
          if (this.sectionOrder === 1) {
            if (correct_count >= this.adaptiveLine.first_correct_count) {
              this.practices = this.adaptivePractice.compose_practices[1].practices;
              this.examId = this.adaptivePractice.compose_practices[1].exam.id;
              this.adaptiveLine.line.first = "H";
            } else {
              this.adaptiveLine.line.first = "E";
            }
          }
          if (this.sectionOrder === 3) {
            if (this.adaptiveLine.line.first === "H") {
              if (correct_count >= this.adaptiveLine.last_correct_count) {
                this.practices = this.adaptivePractice.compose_practices[3].practices;
                this.examId = this.adaptivePractice.compose_practices[3].exam.id;
              }
            } else if (this.adaptiveLine.line.first === "E") {
              if (correct_count >= this.adaptiveLine.last_correct_count) {
                this.practices = this.adaptivePractice.compose_practices[2].practices;
                this.examId = this.adaptivePractice.compose_practices[2].exam.id;
              }
            }
          }
          const data = await SAT.getPastComposePractice(this.userExamId);
          this.setUserTest(data);
        }
        this.sectionOrder++;
        this.setNextSection();
        if (
          this.breakTime[`section${this.sectionOrder - 1}${this.sectionOrder}`]
            .hasBreak
        ) {
          this.showQuestion = false;
        }
        this.isReviewPage = false;
      } else {
        await this.updateSatAnswers(
          true,
          seconds ? seconds.questionSecondsSpent : 0,
          seconds ? seconds.testTime : 0
        );
        this.isReviewPage = false;
        this.$router.replace({
          name: "SATResult",
          query: { practiceType: this.practiceType },
          params: { id: this.userExamId }
        });
      }
    },
    nextPage() {
      this.page++;
    },
    returnToHome() {
      this.$router.replace({
        name: "SATList"
      });
    },
    leaveAlert() {
      setInterval(() => {
        this.spent++;
      }, 1000);
      let vm = this;
      let leaveStart = 0;
      window.onblur = function() {
        if (vm.page === 3) {
          vm.showLeaveAlert = false;
          leaveStart = vm.spent;
          vm.leaveTimes++;
          document.title =
            "Warning: Do not leave the browser in the middle of the test";
          let nowTime = vm.$refs.QuestionWrapper.remainExamTimeSeconds;
          let type = "Leave";
          vm.question.metadata.leaveActivities.push({
            nowTime,
            type
          });
        }
      };
      window.onfocus = function() {
        if (vm.page === 3) {
          vm.leaveSeconds += vm.spent - leaveStart;
          document.title = "Test - " + vm.CompanyName;
          if (vm.leaveSeconds > 0) {
            vm.showLeaveAlert = true;
            let nowTime = vm.$refs.QuestionWrapper.remainExamTimeSeconds;
            let type = "Back";
            vm.question.metadata.leaveActivities.push({
              nowTime,
              type
            });
          }
        }
      };
    },
    beforeUnloadHandler(e) {
      e.returnValue = "Are you sure you want to leave this page?";
      return " ";
    },
    unloadHandler(e) {
      e.returnValue = "Are you sure you want to leave this page?";
      return " ";
    },
    setSection() {
      this.practice = this.practices[this.sectionOrder - 1];
      this.testTime = 0;
      this.testTime = this.setExamTimeSeconds();
      this.questions = this.practice.exam_questions;
    },
    setQuestion() {
      if (this.questions) {
        let question = this.questions[this.questionOrder - 1];
        if (question["answer"]) {
          question["answer"];
          // question["answer"] = question.question["answer"];
        } else {
          // question.question["answer"] = "";
          question["answer"] = undefined;
        }
        if (question["isMarked"]) {
          question["isMarked"];
        } else {
          question["isMarked"] = false;
        }
        if (question["crossOutActive"]) {
          question["crossOutActive"];
        } else {
          question["crossOutActive"] = false;
        }
        if (question["crossOutArr"]) {
          question["crossOutArr"];
        } else {
          question["crossOutArr"] = [];
        }

        this.question = question;
        this.answer = this.question["answer"];
        this.marked = this.question["isMarked"];
        this.crossOutActive = this.question["crossOutActive"];
        this.crossOutArr = this.question["crossOutArr"];
        this.questionTimeLine = this.question.answer_time
          ? this.question.answer_time
          : 0;
        this.question.metadata.activities.push([
          {
            testTime: this.$refs.QuestionWrapper
              ? this.$refs.QuestionWrapper.remainExamTimeSeconds
              : this.testTime,
            content: "Started question",
            type: "START"
          }
        ]);
        console.log(this.question.metadata.annotation);
        if (this.question.metadata && this.question.metadata.annotation) {
          if (this.question.question.passage) {
            this.question.question.passage.content = this.question.metadata
              .annotation.passage
              ? this.question.metadata.annotation.passage
              : this.question.question.passage.content;
          }
          this.question.question.content = this.question.metadata.annotation
            .question
            ? this.question.metadata.annotation.question
            : this.question.question.content;
          if (this.question.question.options) {
            this.question.question.options.forEach((option, index) => {
              option.title = this.question.metadata.annotation.options[
                index
              ].title;
            });
          }
        }
        if (this.$refs.QuestionWrapper) {
          this.$refs.QuestionWrapper.setHighlight();
        }
      }
    },
    setNextSection() {
      this.setSection();
      this.questionOrder = 1;
      this.setQuestion();
    },
    setExamTimeSeconds() {
      let SectionName = this.titleCase(this.practice.name);
      let testTime = 35 * 60;
      if (SectionName == "English") {
        testTime = 32 * 60;
      } else if (SectionName == "Math") {
        testTime = 35 * 60;
      } else {
        testTime = 35 * 60;
      }
      return Math.round(testTime);
    },
    changePhone() {
      this.$router.replace({
        name: "SATMobileTest",
        query: this.$route.query,
        params: this.$route.params
      });
    },
    async startTest() {
      const res = await SATTest.getUserExam(this.$route.params.userExamId);
      await this.setUserTest(res);
    },
    setUserTest(res) {
      res.exam.exam_sections.forEach(section => {
        section.exam_questions.forEach(question => {
          question.question.options.forEach(option => {
            option["letter"] = option["label"];
            option["title"] = option["content"];
          });
        });
      });
      this.practices = res.exam.exam_sections;
      //获取之前的考试
      this.userExamAnswers = res.user_exam.user_exam_answers;
      this.setAnswers();

      this.sectionOrder =
        res.user_exam.metadata && res.user_exam.metadata.sectionOrder
          ? res.user_exam.metadata.sectionOrder
          : this.sectionOrder;
      this.practice = this.practices[this.sectionOrder - 1];
      this.questions = this.practice.exam_questions;
      this.setSection();
      this.questionOrder =
        res.user_exam.metadata && res.user_exam.metadata.questionOrder
          ? res.user_exam.metadata.questionOrder
          : this.questionOrder;
      // if (this.questionOrder > 1) {
      //   this.page = 3;
      // }
      this.setQuestion();
    },
    async onExitTest(isEnd, seconds) {
      await this.updateAnswers();
      if (isEnd) {
        this.$router.replace({
          name: "SATResultPage",
          params: { userExamId: this.$route.params.userExamId }
        });
      } else {
        this.$router.replace({
          name: "SATList"
        });
      }
    },

    //清除高亮
    async onNextQuestion() {
      if (this.questionOrder !== this.questions.length) {
        await this.updateAnswers();
        await this.questionOrder++;
        this.setQuestion();
      }
    },
    async onBackQuestion() {
      if (this.questionOrder !== 0) {
        await this.updateAnswers();
        await this.questionOrder--;
        this.setQuestion();
      }
    },
    //结束当前单元
    async onSubmitSection(
      seconds,
      remainInterval,
      questionSecondsSpentInterval
    ) {
      if (this.sectionOrder !== this.practices.length) {
        clearInterval(remainInterval);
        clearInterval(questionSecondsSpentInterval);
        await this.updateAnswers();
        this.sectionOrder++;
        this.setNextSection();
        if (
          this.breakTime[`section${this.sectionOrder - 1}${this.sectionOrder}`]
            .hasBreak
        ) {
          this.showQuestion = false;
        }
        this.isReviewPage = false;
      } else {
        await this.updateAnswers();
        await SATTest.submitExam(this.$route.params.userExamId);
        this.$router.replace({
          name: "SATResultPage",
          params: { userExamId: this.$route.params.userExamId }
        });
      }
    },
    async onEndSection() {},
    onChangeAnswer(value) {
      if (this.crossOutArr.indexOf(value) > -1) {
        let index = this.crossOutArr.indexOf(value);
        if (index > -1) {
          this.crossOutArr.splice(index, 1);
        }
      }
      this.answer = value;
      this.question.question.answer = value;
      this.question.answer = value;
      this.questions[this.questionOrder - 1].answer = value;
      this.questions[this.questionOrder - 1].question.answer = false;
      // this.$set(
      //   // this.questions,
      //   this.questionOrder - 1,
      //   this.questions[this.questionOrder - 1]
      // );
      this.question.metadata.activities[
        this.question.metadata.activities.length - 1
      ].push({
        testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
        content: `Answer ${value}`,
        answer: this.answer,
        type: "ANSWER"
      });
    },
    // //选择选项
    // onChangeAnswer(value) {
    //   this.answer = value;
    //   // this.question.question.answer = value;
    //   console.log(this.$refs.QuestionWrapper.remainExamTimeSeconds);
    //   this.question.answer = value;
    //   this.questions[this.questionOrder - 1].answer = value;
    //   this.questions[this.questionOrder - 1].question.answer = false;
    //   this.$set(
    //     this.questions,
    //     this.questionOrder - 1,
    //     this.questions[this.questionOrder - 1]
    //   );

    //   //更新答题的细节
    //   this.question.metadata.activities[
    //     this.question.metadata.activities.length - 1
    //   ].push({
    //     testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
    //     content: `Answer ${value}`,
    //     answer: value,
    //     type: "ANSWER"
    //   });
    //   console.log(this.$refs.QuestionWrapper.remainExamTimeSeconds);
    // },
    // 更新答案
    async updateAnswers() {
      this.loading = true;
      //更新答题的细节
      if (
        this.question.metadata.activities &&
        this.question.metadata.activities.length > 0
      ) {
        let content = this.question.answer
          ? `Submitted answer ${this.question.answer}`
          : "Skipped";
        this.question.metadata.activities[
          this.question.metadata.activities.length - 1
        ].push({
          testTime: this.$refs.QuestionWrapper.remainExamTimeSeconds,
          content: content,
          answer: this.question.answer ? this.question.answer : "",
          type: "SUBMIT"
        });
      }
      //去后端更新答案
      await SATTest.updatePracticeAnswers(this.$route.params.userExamId, {
        exam_question_id: this.question.id,
        answer: this.question.answer ? [this.question.answer] : [],
        metadata: this.question.metadata
      });
      this.loading = false;
      this.updateExamMetaData();
    },
    async updateExamMetaData() {
      await SATTest.updateExamMetaData(this.$route.params.userExamId, {
        metadata: {
          questionOrder: this.questionOrder,
          sectionOrder: this.sectionOrder
        }
      });
    },
    onOrderQuestion(order) {
      this.updateAnswers();
      this.questionOrder = order;
      this.setQuestion();
    },
    //把历史答案引入题目中
    setAnswers() {
      this.practices.forEach(practice => {
        practice.exam_questions.forEach(question => {
          let answer = null;
          let metadata = { activities: [], leaveActivities: [] };
          if (this.userExamAnswers && this.userExamAnswers.length > 0) {
            this.userExamAnswers.forEach(userAnswer => {
              if (question.id === userAnswer.exam_question_id) {
                answer =
                  userAnswer.answer && userAnswer.answer[0]
                    ? userAnswer.answer[0]
                    : "";
                metadata = userAnswer.metadata
                  ? userAnswer.metadata
                  : { activities: [], leaveActivities: [] };
              }
            });
          }
          question["answer"] = answer;
          question["metadata"] = metadata;
        });
      });
    }
  }
};
</script>

<style scoped>
.practice-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  font-weight: 500;
  /* user-select: none; */
}
.test-paper {
  padding-top: 0;
  max-width: 2000px;
  width: 100%;
  /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
}
/* ::v-deep p {
  margin: 0;
} */
.change-phone {
  width: 100%;
  background: var(--themeColor);
  position: fixed;
  z-index: 99999;
  bottom: 0;
  padding: 15px;
  color: white;
}
.change-phone h6 {
  margin-bottom: 15px;
  color: white;
}
.change-phone-cover {
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: block;
  }
}
::v-deep .MathJax .mrow {
  font-size: 1.2rem !important;
}
</style>
